

<template>


  <v-card
    class="content-bg card mx-auto"
    :max-width="card.maxWidth"
    flat
    tile
    router
    :to="`/watch/${video._id}`"
  >
  <!-- :src="`${url}/uploads/thumbnails/${video.thumbnailUrl}`" -->
    <v-img v-if="video.thumbnailUrl !== 'no-photo.jpg'" :src="`${video.thumbnailUrl}`">
      <div class="watch_timer" v-if="video.duration != 0">
      {{getTwoDecimal(video.duration)}}
      </div>
    </v-img>
    <v-img v-else :src=" `${url}/no_image.jpg`" >
      <div class="watch_timer" v-if="video.duration != 0">
        {{getTwoDecimal(video.duration)}}
      </div>
    </v-img>
         <div class="video_titles_top" v-if="video.title.length > 20">
          <v-card-title class="pl-2 pt-3 video_titles_top" style="line-height: 1.2rem" >
            {{ video.title.substring(0,25)+"..." }}
            <!-- {{ video.title }} -->
          </v-card-title>
        </div>
        <div class="video_titles_top" v-if="video.title.length < 20">
          <v-card-title class="pl-2 pt-3 video_titles_top" style="line-height: 1.2rem" >
            {{ video.title }}
          </v-card-title>
        </div>
    <v-row no-gutters>
       <v-col cols="2" v-if="card.type != 'noAvatar'">

        <v-list-item class="pl-0 pt-2" router :to="`/channels/${channel._id}`">
          <v-list-item-avatar>
            <v-img
              v-if="channel.photoUrl"
              class="elevation-6"
              :src="`${channel.photoUrl}`"
            ></v-img>
            <!-- <img v-else :src=" `${url}/no_image.jpg`" :alt="`${item.subscriberId.photoUrl} avatar`" /> -->
            <v-avatar v-else color="red">
              <span class="white--text headline ">
                {{ channel.channelName.split('')[0].toUpperCase() }}
                </span
              >
            </v-avatar>
          </v-list-item-avatar>
        </v-list-item>
      </v-col>
      <v-col>
      

        <v-card-subtitle class="pl-1 pb-0">
         <b class="top_bold"> {{ channel.channelName }}</b>
        </v-card-subtitle>
        <v-card-subtitle class="pl-1 pt-0 bottom_color_change">
          {{ video.views }} views<v-icon>mdi-circle-small</v-icon
          >{{ dateFormatter(video.createdAt) }}
        </v-card-subtitle>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: "VideoCard",
  props: {
    video: {
      type: Object,
      required: true,
    },
    channel: {
      type: Object,
      required: true,
    },
    card: Object,
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    dateFormatterss(date) {
      const converteddate = moment(date).fromNow();
      const datee = converteddate.split(' '); 
      const finaldate = datee[0]+datee[1];
      const daadad = finaldate.toString()
      // const finaldate = datee[0]+datee[1]+datee[2]
      return daadad
    },

    dateFormatter(date) {
      const converteddate = moment(date).fromNow();
      return converteddate
    },

    // dateFormatteraa(date){
    //   const datee = moment(date).fromNow();
    //   if(datee == 'a day ago' && datee == 'a month ago')
    //   {
    //     console.log("0")
    //   }
    //   else
    //   {
    //     const splitData = datee.split(' ');
    //     // console.log("split data", splitData[0])

    //     if(splitData[1] == "day" || splitData[1] == "hours" || splitData[1] == "day")
    //     {
    //       console.log(splitData[0]+''+splitData[1])
    //     }
      // }
    // },
    getTwoDecimal(duration){
return (duration/100).toFixed(2);   
 }
  },
};
</script>

<style></style>
